






























































import './scss/TheRegistration.scss';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { userNamespace } from '@store/modules/user';
import { UserActionTypes } from '@store/modules/user/Types';

@Component({
    name: 'Auth',

    components: {
        BaseInput: () => import('@components/BaseInputs/BaseInput.vue'),
    },
})
export default class TheRegistration extends Vue {
    @userNamespace.Action(UserActionTypes.A_COMPLETE_REGISTRATION) completeRegistration!: (payload) => Promise<void>;
    @userNamespace.Action(UserActionTypes.A_REGISTRATION_INFO) getRegistrationInfo!: (payload) => Promise<any>;

    fields = {
        name: '',
        email: '',
        password: '',
    }
    errors = {
        name: false,
        password: false,
    }
    realtimeValidation:boolean = false;
    accessId:string = '';
    canRegister:boolean = false;
    btnDisabled:boolean = false;

    mounted() {
        this.checkRegistrationInfo();
    }

    get errorExists() {
        return Object.values(this.errors).some((item) => item);
    }

    checkRegistrationInfo() {
        if (this.$route.query.id) {
            this.accessId = this.$route.query.id.toString();
            this.getRegistrationInfo({id: this.accessId}).then((data) => {
                if (data.canRegister) {
                    this.canRegister = data.canRegister;
                }
                if (data.email) {
                    this.fields.email = data.email;
                }
            });
        }
    }

    validateFields() {
        this.errors.name = !this.fields.name;
        this.errors.password = !this.fields.password;
    }

    checkValidation() {
        if (this.realtimeValidation) {
            this.validateFields();
        }
    }

    checkSendForm() {
        if (this.canRegister) {
            this.sendForm();
        }
    }

    sendForm() {
        this.validateFields();
        if (!this.errorExists) {
            this.btnDisabled = true;
            this.completeRegistration({
                id: this.accessId,
                fullName: this.fields.name,
                password: this.fields.password,
            }).then(() => {
                this.btnDisabled = false;
                this.$router.push({ path: '/projects' });
            })
        }
    }
}
